<template>
  <div class="food-safety-detect">
    <a-row>
      <a-col :span="18">
        <div class="detect">
          <div class="title">本月（9月）</div>
          <div class="bd">
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-chushimao"
                />
              </div>
              <div class="text">
                <h4>厨师帽</h4>
                <p class="fred"><b class="fnum">2</b>次违规</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-chushifu"
                />
              </div>
              <div class="text">
                <h4>厨师服</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-shoutao"
                />
              </div>
              <div class="text">
                <h4>手套</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-xiyuan"
                />
              </div>
              <div class="text">
                <h4>吸烟</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-kozhao"
                />
              </div>
              <div class="text">
                <h4>口罩</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-shouji"
                />
              </div>
              <div class="text">
                <h4>玩手机</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-laoshu"
                />
              </div>
              <div class="text">
                <h4>老鼠</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <icon-font
                  slot="prefix"
                  type="icon-lajitong"
                />
              </div>
              <div class="text">
                <h4>垃圾桶</h4>
                <p class="fgreen">无异常</p>
              </div>
            </div>
          </div>
        </div>
        <div class="video">
          <div class="title">实时监控</div>
          <div class="bd">
            <a-row>
              <a-col :span="8">
                <div class="item">
                  <img src="~@/assets/images/temp/video-1.jpg" />
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item">
                  <img src="~@/assets/images/temp/video-2.jpg" />
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item">
                  <img src="~@/assets/images/temp/video-3.jpg" />
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item">
                  <img src="~@/assets/images/temp/video-4.jpg" />
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item">
                  <img src="~@/assets/images/temp/video-5.jpg" />
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item">
                  <img src="~@/assets/images/temp/video-6.jpg" />
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="history">
          <div class="title"><span>历史记录</span>
            <a-date-picker size="large" />
          </div>
          <div class="bd">
            <div class="item">
              <div class="icte">
                <icon-font
                  slot="prefix"
                  type="icon-shipin"
                  class="icon"
                />
                <div class="text">通道01</div>
              </div>
              <div class="button">
                <a-button
                  type="primary"
                  ghost
                >下载</a-button>
              </div>
            </div>
            <div class="item">
              <div class="icte">
                <icon-font
                  slot="prefix"
                  type="icon-shipin"
                  class="icon"
                />
                <div class="text">通道02</div>
              </div>
              <div class="button">
                <a-button
                  type="primary"
                  ghost
                >下载</a-button>
              </div>
            </div>
            <div class="item">
              <div class="icte">
                <icon-font
                  slot="prefix"
                  type="icon-shipin"
                  class="icon"
                />
                <div class="text">通道03</div>
              </div>
              <div class="button">
                <a-button
                  type="primary"
                  ghost
                >下载</a-button>
              </div>
            </div>
            <div class="item">
              <div class="icte">
                <icon-font
                  slot="prefix"
                  type="icon-shipin"
                  class="icon"
                />
                <div class="text">通道04</div>
              </div>
              <div class="button">
                <a-button
                  type="primary"
                  ghost
                >下载</a-button>
              </div>
            </div>
            <div class="item">
              <div class="icte">
                <icon-font
                  slot="prefix"
                  type="icon-shipin"
                  class="icon"
                />
                <div class="text">通道05</div>
              </div>
              <div class="button">
                <a-button
                  type="primary"
                  ghost
                >下载</a-button>
              </div>
            </div>
            <div class="item">
              <div class="icte">
                <icon-font
                  slot="prefix"
                  type="icon-shipin"
                  class="icon"
                />
                <div class="text">通道06</div>
              </div>
              <div class="button">
                <a-button
                  type="primary"
                  ghost
                >下载</a-button>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="sten">
          <div class="staff">
            <div class="title">员工</div>
            <div class="bd">
              <div
                class="item"
                v-for="item in list"
                :key="item.id"
              >
                <div class="najo">
                  <div class="name">{{item.name}}</div>
                  <div class="job">{{item.job}}</div>
                </div>
                <div class="temp">{{item.temp}}</div>
              </div>
            </div>
          </div>
          <div class="environment">
            <div class="title">环境监测</div>
            <div class="bd">
              <a-row>
                <a-col :span="12">
                  <div class="item">
                    <div class="text">厨房温度</div>
                    <div class="fnum">26.6°</div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="item">
                    <div class="text">厨房湿度</div>
                    <div class="fnum">65%</div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="item">
                    <div class="text">PM2.5</div>
                    <div class="fnum">27.3°</div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="item">
                    <div class="text">燃气泄漏监测</div>
                    <div class="fnum">65%</div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import IconFont from '@/components/Iconfont'
export default {
  components: {
    IconFont
  },
  data () {
    return {
      list: [
        {
          id: 0,
          name: '陈东豪',
          job: '厨工',
          temp: '36.78°'
        },
        {
          id: 1,
          name: '陈坤',
          job: '厨工',
          temp: '36.83°'
        },
        {
          id: 2,
          name: '周思婷',
          job: '服务员',
          temp: '36.91°'
        },
        {
          id: 3,
          name: '潘浩然',
          job: '服务员',
          temp: '36.83°'
        },
        {
          id: 4,
          name: '周翔豪',
          job: '保洁员',
          temp: '36.77°'
        },
        {
          id: 5,
          name: '周兰',
          job: '保洁员',
          temp: '36.83°'
        }
        // {
        //   id:6,
        //   name:"陈东豪",
        //   job:"厨工",
        //   temp:"36.78°",
        // },
        // {
        //   id:7,
        //   name:"陈坤",
        //   job:"厨工",
        //   temp:"36.83°",
        // },
        // {
        //   id:8,
        //   name:"周思婷",
        //   job:"服务员",
        //   temp:"36.91°",
        // },
        // {
        //   id:9,
        //   name:"潘浩然",
        //   job:"服务员",
        //   temp:"36.83°",
        // },
        // {
        //   id:10,
        //   name:"周翔豪",
        //   job:"保洁员",
        //   temp:"36.77°",
        // },
        // {
        //   id:11,
        //   name:"周兰",
        //   job:"保洁员",
        //   temp:"36.83°",
        // },
      ]
    }
  },
  methods: {
    go () {
      this.$router.push({
        path: '/ClassStatistics'
      })
    }
  }
}

</script>

<style lang="less">
.food-safety-detect {
  .detect {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    > .title {
      font-size: 20px;
      padding: 16px 16px 12px;
    }
    > .bd {
      padding: 4px 0 16px;
      display: flex;
      .item {
        flex: 1;
        text-align: center;
        border-right: 1px dotted #e7e7eb;
        &:last-child {
          border-right: none;
        }
        > .icon {
          .anticon {
            font-size: 20px;
          }
        }
        .text {
          margin-top: 2px;
          h4 {
            font-size: 15px;
            margin: 0;
          }
          p {
            margin: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
  .video {
    margin-top: 24px;
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    > .title {
      font-size: 20px;
      padding: 16px 16px 12px;
    }
    > .bd {
      margin-top: -8px;
      padding: 0 8px 8px;
      .item {
        padding: 8px;
        img {
          width: 100%;
        }
      }
    }
  }
  .history {
    margin-top: 24px;
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    > .title {
      padding: 16px 16px 12px;
      > span {
        margin-right: 12px;
        font-size: 20px;
      }
    }
    > .bd {
      display: flex;
      padding: 16px 0 20px;
      .item {
        flex: 1;
        text-align: center;
        border-right: 1px dotted #e7e7eb;
        &:last-child {
          border-right: none;
        }
        .icte {
          .icon {
            font-size: 24px;
          }
        }
        .button {
          margin-top: 8px;
        }
      }
    }
  }
  .sten {
    padding-left: 24px;
    .staff {
      border: 1px solid #e7e7eb;
      background: #fff;
      border-radius: 6px;
      padding: 0 16px;
      > .title {
        font-size: 20px;
        padding: 16px 0 12px;
      }
      > .bd {
        max-height: 673px;
        overflow-y: scroll;
        .item {
          padding: 10px 0;
          display: flex;
          align-items: center;
          border-top: 1px dotted #e7e7eb;
          .najo {
            flex: 1;
            .name {
              font-size: 15px;
              font-weight: bold;
            }
            .job {
              font-size: 12px;
              margin-top: -2px;
            }
          }
        }
      }
    }
    .environment {
      margin-top: 24px;
      border: 1px solid #e7e7eb;
      background: #fff;
      border-radius: 6px;
      > .title {
        font-size: 20px;
        padding: 16px 16px 12px;
      }
      > .bd {
        .item{
          padding:0 16px;
          margin:5px 0 16px;
          border-right: 1px dotted #e7e7eb;
          margin-right: -1px;
          .text{
            font-size: 12px;
          }
          .fnum{
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
